import React, {useEffect} from 'react';
import {CalendarIcon, LocationMarkerIcon} from "@heroicons/react/solid";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {InformationCircleIcon} from "@heroicons/react/outline";

let interval;

function EventHeader({DeviceStore}){

  const {t, queueData} = DeviceStore;

  DeviceStore.navigate = useNavigate();

  useEffect(() => {
    interval = setInterval(DeviceStore.checkReservation, 15000);
    return () => {
      clearInterval(DeviceStore.checkReservation)
    }
  }, [])

  const {locale, date, defaultLocale, theme} = DeviceStore;

  var event = {};
  var dateString = '';

  if(DeviceStore.eventData) {
    const {dates} = DeviceStore.eventData;
    event = DeviceStore.eventData.event;


    dateString = event.type === 'recurring' ? t('multiple_dates') : (event.date[locale] ?? (event.date[defaultLocale] ?? t('unknown_date')));

    if (date) {
      const dateObj = dates.find(d => d.id === date)
      dateString = dateObj.label;
    }
  }

  const eventLocation = event.location;
  const eventName = event.name || queueData.event_name;

  if(!eventName){
    return null;
  }

  const locationLabel = eventLocation ? [eventLocation.name, eventLocation.city].filter(v => v).join(', ') : null;
  const locationAddress = eventLocation ? [eventLocation.address ?? eventLocation.name,  (eventLocation.zipcode ? (eventLocation.zipcode + ' ') : '')  + eventLocation.city].filter(v => v).join(', ') : null;

  return (

      <div className="event-header overflow-hidden shadow-md my-4 rounded-md">

        {!theme.image ? null : <div className="shop-header bg-gray-800 text-white relative">

          <img src={theme.image} alt="" className={'header-image block w-full object-cover ' + (DeviceStore.iframe ? 'hidden' : '')}/>

        </div>}

        <div className="block p-4 py-3 bg-blue-600 relative" style={{backgroundColor: theme.primary_color}}>
          <h1 className={'event-title text-xl font-semibold mb-1 md:mb-0 ' + (theme.primary_light ? 'text-black' : 'text-white')} >{eventName}</h1>
          <div className={'md:flex items-center space-y-1 md:space-y-0 md:space-x-4 text-sm ' + (theme.primary_light ? 'text-black' : 'text-white')}>
            {dateString ? <h2 className="event-date flex items-center">
              <CalendarIcon className="h-4 mr-2"/>{dateString}
            </h2> : null }
            {locationLabel ?
            <a href={'https://www.google.nl/maps/search/'+locationAddress.replace(/\s+/g, '+')} target="_blank" rel="noreferrer">
              <h3 className="event-location flex items-center">
                <LocationMarkerIcon className="h-4 mr-2"/>
                {locationLabel}
              </h3>
            </a> : null }
          </div>
          {DeviceStore.eventData ? <button type="button" className={"organisation-info-btn text-white absolute right-2 bottom-2 opacity-75 " + (theme.primary_light ? 'text-black' : 'text-white') } onClick={DeviceStore.toggleOrganisationInfo}>
            <InformationCircleIcon className={'h-6 ' + (theme.primary_light ? 'text-black' : 'text-white')}/>
          </button> : null }
        </div>
      </div>
  )
}

export default inject("DeviceStore")(observer(EventHeader));
