import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {inject} from "mobx-react";
import {useCollapse} from 'react-collapsed'
import Countdown from 'react-countdown';
import moment from "moment";
import {pad} from "../helpers";

function TicketRow({DeviceStore, ticket, chooseVariants, chooseSeats, category}){

  const {t, locale, defaultLocale, theme, loading, popup} = DeviceStore;
  const {event} = DeviceStore.eventData;

  const [showImage, setShowImage] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse();

  const increase = () => {
    if(value >= ticket.userlimit ?? 25) return;
    if(ticket.usermin > value + ticket.increments){
      DeviceStore.updateTicketAmount(ticket.id, ticket.usermin)
    } else {
      DeviceStore.updateTicketAmount(ticket.id, value + ticket.increments)
    }
    DeviceStore.pixelAddToCart(ticket.name[locale], ticket.id, ticket.price)
  }

  const decrease = () => {
    if(!value) return;

    if(ticket.usermin > value - ticket.increments){
      DeviceStore.updateTicketAmount(ticket.id, 0)
    } else {
      DeviceStore.updateTicketAmount(ticket.id, value - ticket.increments)
    }
  }

  const value = DeviceStore.selectedTickets.filter(t => t.tickettype_id === ticket.id).length;

  if(!value && popup && category?.collapsed) return null;

  let ticketFee = DeviceStore.eventData.event.customer_ticket_fee;

  const renderInput = ({minutes, seconds}) => {

    const time = moment().unix();

    if(ticket.sold_out){
      return (<p className="w-32 text-xs text-gray-500 border-gray-200 border flex p-1 rounded justify-center items-center text-center dark:bg-gray-950 dark:border-gray-700 dark:text-gray-400" style={{minHeight: '40px'}}>
        {ticket.sold_msg && ticket.sold_msg[locale] ?ticket.sold_msg[locale] : t('sold_out')}
      </p>)
    }

    if(ticket.sell_end && ticket.sell_end < time){
      return (<p className="w-32 text-xs text-gray-500 border-gray-200 border flex p-1 rounded justify-center items-center text-center dark:bg-gray-950 dark:border-gray-700 dark:text-gray-400" style={{minHeight: '40px'}}>
        {ticket.stopped_msg && ticket.stopped_msg[locale] ? ticket.stopped_msg[locale] : t('not_available')}
      </p>)
    }

    if(ticket.sell_start && ticket.sell_start > time){
      if(ticket.sell_start - time < 3600){
        return (
            <p className="w-32 text-gray-600 border-gray-200 border flex p-1 rounded justify-center items-center text-center dark:bg-gray-950 dark:border-gray-700 dark:text-gray-400" style={{height: '40px'}}>
              {pad(minutes)}:{pad(seconds)}
            </p>
        );
      }

      return (<p className="w-32 text-xs text-gray-500 border-gray-200 border flex p-1 rounded justify-center items-center text-center dark:bg-gray-950 dark:border-gray-700 dark:text-gray-400" style={{minHeight: '40px'}}>
        {ticket.stopped_msg && ticket.stopped_msg[locale] ? ticket.stopped_msg[locale] : t('not_available')}
      </p>)
    }


    if(ticket.variants.length){
      const selected = DeviceStore.selectedTickets.filter(t => t.tickettype_id === ticket.id).length;

      if(!selected){
        return <button onClick={() => chooseVariants(ticket)} disabled={loading} className={"add-btn w-32 h-10 bg-blue-600 p-4 py-2 font-bold text-sm rounded " + (theme.primary_light ? 'text-black' : 'text-white')} style={{height: '42px', backgroundColor: theme?.primary_color}}>{t('choose_tickets')}</button>
      }


      return <div className="flex space-x-4 items-center">
        <button onClick={() => chooseVariants(ticket)} disabled={loading} className="flex items-center space-x-2 text-black border border-gray-300 w-32 justify-between p-4 py-2 rounded dark:border-gray-700 dark:bg-gray-950 dark:text-gray-300" style={{color: theme?.primary_color, borderColor: theme?.primary_color}}>
          <span>{selected + (selected === 1 ? ' ticket ' : ' tickets')}</span>
          <svg xmlns="http://www.w3.org/2000/svg"  style={{color: theme?.primary_color}} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>
    }

    if(ticket.seatplan_rank){
      const selectedAmount = DeviceStore.selectedTickets.filter(s => s.tickettype_id === ticket.id).length
      if(selectedAmount){
        return <div className="flex space-x-4 items-center">
          <button onClick={() => chooseSeats(ticket)} disabled={loading} className={'w-32 h-10 flex items-center justify-between space-x-2 border border-gray-300 rounded ' + (loading ? ' opacity-20 cursor-not-allowed' : '')} style={{color: theme?.primary_color, borderColor: theme?.primary_color}}>
            <span className="block whitespace-nowrap overflow-hidden text-center pl-2" style={{width: '80px'}}>{selectedAmount} <span className="text-xs">{selectedAmount > 1 ? t('seats') : t('seat')}</span></span>
            <span className="border-l h-10 flex items-center justify-center w-10" style={{borderColor: theme?.primary_color}}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
            </svg>
            </span>


          </button>
        </div>
      }
      return <button onClick={() => chooseSeats(ticket)} disabled={loading} className={"add-btn w-32 h-10 bg-blue-600 p-2 py-2 font-bold rounded text-sm truncate " + (loading ? ' opacity-20 cursor-not-allowed' : '') + (theme.primary_light ? 'text-black' : 'text-white')} style={{backgroundColor: theme?.primary_color}}>{t('choose_seats')}</button>
    }

    if(!value){
      return <button onClick={increase} disabled={loading} className={"add-btn w-32 h-10 bg-blue-600 p-4 py-2 font-bold text-sm rounded "  + (loading ? ' opacity-20 cursor-not-allowed ' : '') + (theme.primary_light ? ' text-black ' : ' text-white ')} style={{height: '42px', backgroundColor: theme?.primary_color}}>{t('choose_tickets')}</button>
    }

    return (
        <div className="flex border border-gray-300 rounded items-center w-32 bg-white dark:bg-gray-950 dark:text-white dark:border-gray-700">
          <button onClick={decrease} disabled={loading} className="decrease-ticket-btn p-2 border-r w-10 dark:border-gray-700"><span className={!value || loading ? " opacity-20 cursor-not-allowed" : ""}>-</span></button>
          <input name={'ticket_select_'+ticket.id} className="ticket-amount-label border-none w-12 text-center outline-none focus:ring-0 dark:bg-gray-950" readOnly={true} value={value} type="text"/>
          <button onClick={increase} disabled={loading} className="increase-ticket-btn p-2 border-l w-10 dark:border-gray-700"><span className={value >= ticket.userlimit || loading ? " opacity-20 cursor-not-allowed " : ""}>+</span></button>
        </div>
    )
  }

  const showExFee = event.show_tickets_exfee;

  const name = ticket.name[locale] ?? ticket.name[defaultLocale];
  const description = ticket.description ? (ticket.description[locale] ?? ticket.description[defaultLocale]) : null;

  let ticketPrice = ticket.price + (ticket.price && !showExFee ? ticketFee : 0);

  if(ticket.userlimit === ticket.usermin){
    ticketPrice *= ticket.userlimit;
    ticketFee *= ticket.userlimit;
  }

  return (
      <div className="ticket-row">

        {showImage ? <div className="fadeIn fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 z-40 backdrop-blur-sm" onClick={() => setShowImage(false)}>
          <div className="max-w-2xl mx-auto h-full flex items-center">
            <img src={ticket.image} alt={name} className="w-full max-h-full object-contain"/>
          </div>
        </div> : null}

        <div className="xsgrid grid-cols-6 sm:grid-cols-12 justify-between items-center border-b border-gray-200 p-4 dark:border-gray-700 dark:bg-gray-900">
          <div className="col-span-6 flex">
            <div className="ticket-image hidden">
              <div onClick={() => ticket.image ? setShowImage(true) : null} className={'w-20 h-20 border dark:border-gray-800 rounded mr-4 ' + (ticket.image ? 'cursor-pointer' : '')} style={{backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: 'url(' + (ticket.image ? ticket.image : '/images/image_placeholder.png') + ')'}}></div>
            </div>
            <div>
              <h4 className="font-medium text-gray-800 text-md lg:text-lg pr-4 dark:text-gray-200">
                <span {...getToggleProps()}>{name}</span>
                {description ? <button {...getToggleProps()} className="md:hidden text-gray-400 px-1 inline">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"/>
                  </svg>
                </button> : null}
              </h4>
              <p className="ticket-description ticket-description-desktop text-xs text-gray-600 pr-4 hidden md:block dark:text-gray-400" style={{maxWidth: '500px', whiteSpace: 'pre-line'}}>{description}</p>
            </div>
          </div>
          <div className="flex justify-ticket items-center space-x-4 col-span-6">
            <div>
              <p className="ticket-price price-label lg:text-lg dark:text-gray-200">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(ticketPrice)}</p>
              {ticketFee && ticket.price ?
                  <p style={{fontSize: '10px', lineHeight: '12px'}} className="ticket-price-fee text-gray-600 dark:text-gray-400 text-right minw75">{showExFee ? '+' : 'Incl.'} {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(ticketFee)} fee</p> : null
              }
            </div>
            <div className="ticket-input">
              <Countdown
                  date={Math.max(ticket.sell_start * 1000, 1)}
                  renderer={renderInput}
              />
            </div>
          </div>
        </div>
        <div  className="md:hidden">
        { description ? <div {...getCollapseProps()}>
          <p className="text-xs text-gray-600 p-3 bg-gray-100 dark:bg-gray-700 dark:text-gray-300 border-b dark:border-gray-700 ticket-description ticket-description-mobile" style={{whiteSpace: 'pre-line'}}>{description}</p>
        </div> : null }
        </div>
      </div>
  )
}

export default inject("DeviceStore")(observer(TicketRow));
