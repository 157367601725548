import React, {useEffect, useState} from "react";
import "../flatpickr.css";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import { inject, observer } from "mobx-react";
import "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/l10n/nl.js";
import "flatpickr/dist/l10n/es.js";
import "flatpickr/dist/l10n/de.js";

function EventDatePicker({ DeviceStore, theme, eventDates, onClick }) {

  const { locale, t } = DeviceStore;
  const [date, setDate] = useState();

  const dates = [...new Set(eventDates.map((d) => d.date))].sort();
  const minDate = dates[0];
  const maxDate = dates[dates.length - 1];

  const hasMulitpleTimes = dates.length !== eventDates.length;

  const times = eventDates
      .filter((d) => d.date === date);

  useEffect(() => {
    if(date) {
      if(times.length === 1 && !hasMulitpleTimes){
        onClick(times[0].id)
      } else {
        document.getElementById('eventTimes').scrollIntoView({behavior: 'smooth'});

      }
    }
  }, [date]);

  return (
      <div id="eventDatePicker" className={(hasMulitpleTimes ? 'grid md:grid-cols-2 p-2' : '')}>
        {theme.primary_color && (
            <style
                dangerouslySetInnerHTML={{
                  __html: `
              .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, 
              .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, 
              .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, 
              .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, 
              .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, 
              .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
                background: ${theme.primary_color} !important;
                border-color: ${theme.primary_color} !important;
                color: ${theme.primary_light ? "black" : "white"};
              }
              .flatpickr-months .flatpickr-month, .flatpickr-current-month .flatpickr-monthDropdown-months {
                background: ${theme.primary_color} !important;
                color: ${theme.primary_light ? "black" : "white"} !important;
              }
            `,
                }}
            />
        )}
        <div className={hasMulitpleTimes ? 'p-2':'-mt-0.5'}>
          {hasMulitpleTimes ? <label className="pb-2 block dark:text-white">{t('Kies een datum:')}</label> : null}
          <Flatpickr
              value={date}
              options={{
                locale,
                inline: true,
                minDate,
                maxDate,
                enable: dates,
              }}
              onChange={([selectedDate]) => {
                const formattedDate = format(selectedDate, "yyyy-MM-dd");
                console.log(formattedDate);
                setDate(formattedDate);
              }}
          />
        </div>

        {hasMulitpleTimes ? <>
          {date ? (
              <div id="eventTimes">
                <label className="p-2 pb-0 block dark:text-white">{t('Kies een tijd:')}</label>
                <div className="md:grid grid-cols-2 gap-2 p-2 space-y-2 md:space-y-0">
                  {times.map((date) => (
                      <button
                          disabled={date.sold_out}
                          className={`block w-full rounded p-4 font-bold border border-transparent bg-black bg-opacity-5 dark:bg-opacity-10 dark:text-white dark:enabled:hover:bg-white enabled:hover:bg-opacity-10 dark:enabled:hover:bg-opacity-5 dark:enabled:hover:border-gray-200`}
                          // style={{ background: theme.primary_color }}
                          key={date.id}
                          onClick={() => onClick(date.id)}
                      >
                        {date.time}
                        <span className="font-normal"> uur</span>
                      </button>
                  ))}
                </div>
              </div>
          ) : (
              <div id="eventTimes">
                <label className="p-2 pb-0 block dark:text-white opacity-25">{t('Kies een tijd:')}</label>
                <div className="md:grid grid-cols-2 gap-2 p-2 space-y-2 md:space-y-0">
                  <div className="block w-full rounded p-4 bg-gray-50 dark:bg-gray-950 dark:border-gray-700">&nbsp;</div>
                  <div className="block w-full rounded p-4 bg-gray-50 dark:bg-gray-950 dark:border-gray-700">&nbsp;</div>
                  <div className="block w-full rounded p-4 bg-gray-50 dark:bg-gray-950 dark:border-gray-700">&nbsp;</div>
                </div>
              </div>
          )}
        </> : null}
      </div>
  );
}

export default inject("DeviceStore")(observer(EventDatePicker));
