const validateSelectedSeats = (seatplan, ticket, seats, selectedSeats, blocked) => {

  if(selectedSeats.length > ticket.userlimit) {
    return 'Kies maximaal ' + ticket.userlimit + ' tickets.';
  }

  seats = seats.map(seat => {
    // if(seat.available) {
      seat.available = !blocked.includes(seat.id);
    // }
    return seat;
  })

  if(!seatplan.validate){
    return null;
  }

  let error = null;

  /**
   * Controleer of alle stoelen per rij aaneengesloten zijn.
   *
   * @param row
   */
  const checkRow = (row) => {
    let startColumn = null;
    let endColumn = null;
    row.forEach(seat => {
      if(startColumn === null && seat.selected){
        startColumn = seat.column;
      }
      if(seat.selected){
        endColumn = seat.column;
      }
    })
    if(startColumn && endColumn) {
      row.filter(seat => seat.column >= startColumn && seat.column <= endColumn).forEach(seat => {
        if (seat.available && !seat.selected) {
          // error = 'Kies aaneengesloten plaatsen.';
        }
      })
    }
  }

  /**
   * Controleer of er aan beide kanten een enkele plek vrij is gelaten
   *
   * @param group
   */
  const checkSubGroup = (group) => {
    if(group.length > 1){
      const first = group[0];
      const second = group[1];
      const last = group[group.length - 1];
      const secondLast = group[group.length - 2];
      const firstEmpty = !first.selected && second.selected;
      const lastEmpty = !last.selected && secondLast.selected;
      let empties = 0;
      let prev = null;
      let secondPrev = null;

      group.forEach(g => {
        if(!g.selected){
          empties++;
        }
        if(prev && secondPrev){
          if(g.selected && !prev.selected && secondPrev.selected) {
            error = 'De stoelselectie laat een vrije plek over tussen de geselecteerde stoelen.'
          }
        }
        if(prev){
          secondPrev = prev;
        }
        prev = g;
      })
      if(firstEmpty && empties > 1){
        error = 'De stoelselectie laat een vrije plek over aan de zijkant van de selectie.';
      }
      if(lastEmpty && empties > 1){
        error = 'De stoelselectie laat een vrije plek over aan de zijkant van de selectie.';
      }
    }
  }

  /**
   * Genereer subgroepen op basis van niet beschikbare plekken
   *
   * @param group
   */
  const checkGroup = (group) => {
    let subGroups = [];
    let i = 0;
    group.forEach(seat => {
      if(seat.available){
        if(!subGroups[i]){
          subGroups[i] = [];
        }
        subGroups[i].push(seat);
      }
      if(!seat.available){
        i++;
      }
    })
    subGroups = subGroups.filter(g => g)
    subGroups.forEach(g => {
      checkSubGroup(g)
    })
  }

  let groups = {};
  let rows = {};

  /**
   * Genereer groepen op basis van rij/groep
   */
  seats.filter(s => s.rank === ticket.seatplan_rank).forEach(seat => {
    seat = JSON.parse(JSON.stringify(seat));
    if(!groups[seat.row + '-' + seat.group]){
      groups[seat.row + '-' + seat.group] = [];
    }
    if(!rows[seat.row]){
      rows[seat.row] = [];
    }
    seat.selected = !!selectedSeats.find(s => s.id === seat.id);

    groups[seat.row + '-' + seat.group].push(seat);
    rows[seat.row].push(seat);
  })

  rows = Object.values(rows);
  groups = Object.values(groups);

  rows.forEach(row => {
    row = row.sort((a,b) => a.column - b.column)
    checkRow(row);
  })

  groups.forEach(group => {
    group = group.sort((a,b) => a.column - b.column)
    checkGroup(group)
  })

  return error;
}

export default validateSelectedSeats
