import React, {useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react-lite";
import {inject} from "mobx-react";
import validateSelectedSeats from "../validateSelectedSeats";
import PrimaryButton from "./PrimaryButton";
var panzoom = require('panzoom');

var interval;

let pz;

function Seatplan({DeviceStore, ticket, close}){

  const state = useLocalObservable(() => ({
    error: null,
    showTip: false,
    blockedSeats: [],
    selectedSeats: [],
  }));

  const closeTip = () => {
    state.showTip = false;
    localStorage.tipShown = true;
  }

  useEffect(() => {
    state.error = null;
    if(!localStorage.tipShown){
      state.showTip = true;
    }

    document.getElementById('seatPicker').addEventListener(
        'touchmove',
        function (event) {
          event.preventDefault();
          return false;
        },
        { passive: false }
    );

    getBlockedSeats();
    interval = setInterval(getBlockedSeats, 10000);

    const element = document.querySelector('#seatplan')
    pz = panzoom(element, {
      minZoom: 0.6,
      maxZoom: 8,
      zoomDoubleClickSpeed: 1, // Disable double tap zoom
      smoothScroll: false,
      bounds: true,
      boundsPadding: 0.5,
      onTouch: (e) => {
        const targetElem = e.target
        if (targetElem.classList && targetElem.classList.contains('available')) {
          return false
        } else {
          e.preventDefault()
        }
      },
    })

    return () => {
      clearInterval(interval)
    }
  }, [])

  if(!ticket){
    return null;
  }

  function zoom(scale) {
    let container = document.querySelector('#seatplan');
    // Find out center of the zooming area:
    let rect = container.getBBox();
    let cx = rect.x + rect.width/2;
    let cy = rect.y + rect.height/2;

    // and zoom in (`pz` is instance of panzoom)
    pz.smoothZoom(cx, cy, scale);
  }

  const zoomIn = () => {
    zoom(1.5);
  }

  const zoomOut = () => {
    zoom(0.5)
  }

  const move = (x,y) => {
    const position = pz.getTransform()
    pz.smoothMoveTo(position.x + x, position.y + y);
  }

  const moveRight = () => {
    move(-300, 0)
  }

  const moveLeft = () => {
    move(300, 0)
  }

  const moveUp = () => {
    move(0, 300)
  }

  const moveDown = () => {
    move(0, -300)
  }

  const getBlockedSeats = async () => {
    state.blockedSeats = await DeviceStore.getBlockedSeats();
  }

  const {t, theme} = DeviceStore;
  const {seatplan} = DeviceStore.eventData;
  const width = Math.round(seatplan.width / seatplan.height * 600);

  const portrait = seatplan.width < seatplan.height;

  const seats = DeviceStore.date ? seatplan.seats.find(s => s.date_id === DeviceStore.date).seats : seatplan.seats;

  const renderSeats = () => {
    return seats.map(seat => {
      const available = seat.rank === ticket.seatplan_rank && !state.blockedSeats.includes(seat.id);
      const selected = DeviceStore.selectedTickets.filter(t => t.seat_id === seat.id).length === 1;
      return (
          <circle
              key={seat.id}
              onClick={() => available ? toggleSeat(seat) : null}
              fill="currentColor"
              className={available ? (selected ? 'available' : 'available text-blue-600') : 'text-gray-300'}
              r={ seat.position.scaleY * 10 }
              cx={ seat.position.left + seat.position.scaleY * 10 }
              cy={ seat.position.top + seat.position.scaleY * 10 }
          />

      )
    })
  }

  const toggleSeat = (seat) => {
    const seatIds = DeviceStore.selectedTickets.filter(t => t.seat_id && t.tickettype_id === ticket.id).map(t => t.seat_id)
    let selectedSeats = seats.filter(s => seatIds.includes(s.id));

    if(seatIds.includes(seat.id)){
      selectedSeats = selectedSeats.filter(s => s.id !== seat.id)
    } else {
      selectedSeats.push(seat);
    }

    seatplan.seats = seatplan.seats.map(s => {
      s.available = !state.blockedSeats.includes(s.id);
      return s;
    })

    state.error = validateSelectedSeats(seatplan, ticket, seats, selectedSeats, state.blockedSeats);

    DeviceStore.toggleSeat(ticket, seat, state.error).then(result => {
      getBlockedSeats()
      if(!result){
        selectedSeats.filter(s => state.blockedSeats.includes(s.id)).forEach(s => {
          DeviceStore.removeSeat(ticket, s);
        });
      }
    });
  }

  const confirm = () => {
    DeviceStore.selectedTickets = DeviceStore.selectedTicketsReserved
    close()
  }

  return (
      <div style={{zIndex: 9999}} id="seatPicker" className="fixed top-0 right-0 bottom-0 left-0 bg-gray-800  z-50 overflow-scroll">

        <div className="mx-2 flex justify-center items-center h-full">
          <div className=" rounded-md" style={{width: portrait ? 'calc(90vh - 200px)' :'90vw'}}>

              <div className="">
                <svg id="seatplan" viewBox={'0 0 ' + width + ' 600'} className="w-full bg-white rounded-lg">
                  <image
                      xlinkHref={seatplan.bg_url}
                      x={seatplan.bg_position.left}
                      y={seatplan.bg_position.top}
                      width={seatplan.width * seatplan.bg_position.scaleX}
                      height={ seatplan.height * seatplan.bg_position.scaleY }
                  />
                  { renderSeats() }
                </svg>
              </div>


          </div>
        </div>

        <div className="hidden lg:block absolute right-8 bottom-20 bg-white rounded border shadow">
          <button onClick={zoomIn} className="block w-7 h-7 border-b text-2xl flex justify-center items-center">+</button>
          <button onClick={zoomOut} className="block w-7 h-7 text-2xl flex justify-center items-center">-</button>
        </div>

        <div className="hidden lg:block absolute right-4 bottom-40 bg-white rounded-full border shadow" style={{width: '66px'}}>
          <div className="flex justify-center">
            <button onClick={moveUp} className="block w-7 h-6 text-2xl flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
              </svg>

            </button>
          </div>
          <div className="flex justify-between">
            <button onClick={moveLeft} className="block w-7 h-4 text-2xl flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
              </svg>

            </button>
            <button onClick={moveRight} className="block w-7 h-4 text-2xl flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
              </svg>

            </button>
          </div>
          <div className="flex justify-center">
            <button onClick={moveDown} className="block w-7 h-6 text-2xl flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
              </svg>

            </button>
          </div>
        </div>


        {state.error ?
            <div className="fixed bottom-16 lg:bottom-0.5 z-20 left-0 right-0 flex justify-center pointer-events-none	">
              <div className="bg-red-600 inline-block p-2 px-3 m-2 text-white rounded text-sm">
              {state.error}
            </div>
        </div> : null}

        {state.showTip ? <div className="fixed left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96 space-x-4 flex shadow-lg">
            <svg
                className=""
                width="100"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 410.041 410.041"
                fill="currentColor"
                xmlSpace="preserve"
            >
              <path d="m380.839 79.887-16.971-16.971c-3.906-3.904-10.236-3.904-14.142 0-3.873 3.873-3.9 10.129-.091 14.042H327.27c-5.522 0-10 4.477-10 10s4.478 10 10 10h22.365c-3.809 3.913-3.782 10.169.091 14.042a9.972 9.972 0 0 0 7.071 2.929 9.97 9.97 0 0 0 7.071-2.929l16.971-16.971c3.905-3.905 3.905-10.237 0-14.142zM241.503 111c1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929c3.873-3.873 3.899-10.129.091-14.042h22.365c5.522 0 10-4.477 10-10s-4.478-10-10-10h-22.365c3.809-3.913 3.782-10.169-.091-14.042-3.906-3.904-10.236-3.904-14.143 0l-16.971 16.971c-3.905 3.905-3.905 10.237 0 14.142L241.503 111zM312.685 133.907v-22.365c0-5.523-4.478-10-10-10s-10 4.477-10 10v22.365c-3.913-3.809-10.168-3.782-14.042.091-3.905 3.905-3.905 10.237 0 14.143l16.971 16.971c1.953 1.953 4.512 2.929 7.071 2.929s5.118-.976 7.071-2.929l16.971-16.971c3.905-3.905 3.905-10.237 0-14.143-3.874-3.873-10.128-3.9-14.042-.091zM292.685 40.009v22.365c0 5.523 4.478 10 10 10s10-4.477 10-10V40.009a9.964 9.964 0 0 0 6.971 2.838c2.56 0 5.118-.976 7.071-2.929 3.905-3.905 3.905-10.237 0-14.143l-16.97-16.97c-3.906-3.905-10.236-3.905-14.143 0l-16.971 16.971c-3.905 3.905-3.905 10.237 0 14.143 3.875 3.872 10.129 3.899 14.042.09z" />
              <path d="M262.705 202.71c-5.296 0-10.547.937-15.492 2.721-7.307-13.762-21.777-23.152-38.4-23.152-3.739 0-7.367.44-10.83 1.269l-.016-140.045C197.967 19.516 178.452 0 154.465 0c-11.496 0-22.337 4.443-30.525 12.511-8.176 8.055-12.781 18.808-12.968 30.278l-.003.164v12.948a46.212 46.212 0 0 0-10.855-1.285c-23.987 0-43.502 19.516-43.502 43.504v129.642c-15.785 5.51-29.605 20.189-30.193 41.04-.917 32.574 1.762 73.801 30.856 103.725 24.543 25.243 62.893 37.514 117.242 37.514 48.934 0 85.145-14.42 107.629-42.859 15.791-19.975 24.139-46.874 24.139-77.787l-.074-43.216c-.002-23.953-19.518-43.469-43.506-43.469zm-88.189 187.331c-114.588 0-129.867-58.086-128.105-120.675.426-15.095 13.402-24.011 24.012-24.011v27.683c0 3.482 2.414 3.887 3.135 3.887.721 0 3.055-.396 3.055-3.878V98.12c0-12.98 10.521-23.504 23.502-23.504 11.91 0 23.287 8.502 23.287 20.35.008.043-.086 112.092-.086 112.092a3.827 3.827 0 1 0 7.654 0V43.116C131.178 30.316 141.613 20 154.465 20c12.979 0 23.502 10.524 23.502 23.504l.02 179.815a3.77 3.77 0 1 0 7.537 0l-.008-.974c0-11.818 11.484-20.067 23.297-20.067 12.982 0 23.455 10.523 23.455 23.504l.047 17.227a3.669 3.669 0 0 0 7.336 0l-.012-1.311c0-10.748 11.629-18.988 23.066-18.988 12.98 0 23.504 10.523 23.504 23.503l.074 43.182c0 40.161-15.619 100.646-111.767 100.646z" />
            </svg>
            <div>
              <p className="text-sm md:hidden">Pinch om te zoomen of beweeg om over de kaart te navigeren.</p>
              <p className="text-sm hidden md:block">Scroll om te zoomen of klik en sleep je muis om over de kaart te navigeren.</p>
              <button onClick={closeTip} type="button" className="text-sm mt-2 text-blue-600" style={{color: theme?.primary_color}}>Sluiten</button>
            </div>
          </div>
        </div> : null}

        <div className="fixed left-0 right-0 top-0 bg-white flex justify-center md:justify-between items-center border p-3 shadow-lg">
          <div className="container flex justify-between">
            <h3 className="hidden md:block ">{DeviceStore.selectedTickets.filter(t => t.tickettype_id === ticket.id).length ? t('chosen_seats') + DeviceStore.selectedTickets.filter(t => t.tickettype_id === ticket.id).length : t('chose_available_seat')}</h3>
            <div className="flex space-x-4 text-xs md:text-sm">
              <div className="flex items-center space-x-1">
                <span className="w-4 h-4 block bg-blue-600 rounded-full"></span>
                <p>{t('available')}</p>
              </div>
              <div className="flex items-center space-x-1">
                <span className="w-4 h-4 block bg-gray-300 rounded-full"></span>
                <p>{t('not_available')}</p>
              </div>
              <div className="flex items-center space-x-1">
                <span className="w-4 h-4 block bg-black rounded-full"></span>
                <p>{t('chosen')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed left-0 right-0 bottom-0 bg-white p-2 border shadow-lg">
          <div className="container w-full flex justify-end">
            {/*<button className="text-sm ml-2" onClick={close}>{t('cancel')}</button>*/}
            <PrimaryButton theme={theme} loading={DeviceStore.loading} label={t('confirm')} onClick={confirm}/>
          </div>
        </div>
      </div>
  )
}

export default inject("DeviceStore")(observer(Seatplan))
